@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');


*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans 3", sans-serif;
  /* font-family: "Poetsen One", sans-serif; */
  /* font-family: "Bebas Neue", sans-serif; */
  font-style: normal;
}

:root{
  --nameCol:#fff;
  --col:#cacecc;
  /* --bl:#000; */
  /* --bl:#25262f; */
  --bl:#1d1d1d;
  --og:#80db66;
  --hov:#61a74d;
}

