.ProjectsDiv {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.projectDivs {
    width: 100%;
    flex-wrap: wrap;
    gap: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.6s ease-in-out;

}

.ProjectsDiv>.info {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-start;
}

.ProjectsDiv .info>h1 {
    color: var(--col);
}

.ProjectsDiv .info h1 span {
    color: var(--og);
    font-size: 30px;
    font-weight: 400;
}


.projectBox:nth-child(even) {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    justify-content: space-between;
    transition: all 0.6s ease-in-out;
}

.projectBox:nth-child(odd) {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px;
    justify-content: space-between;
    transition: all 0.6s ease-in-out;
}

.proImg {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.proImg img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px;
    cursor: pointer;

}

.proImg .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: #65998b6e;
    border-radius: 8px;
    pointer-events: none;
}

.proImg:hover .overlay {
    display: none;

}

.proImg:hover {
    box-shadow: 0 0 10px 1px var(--og);
    border-radius: 8px;
}

.proContent {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 20px 30px;
}

.proContent>div {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.proContent>div p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.2;
    color: var(--og);
}

.proContent>div a {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.5;
    color: var(--col);
    text-decoration: none;
}

.proContent>div a:hover {
    -webkit-text-stroke: 1px var(--og);
    color: var(--col);
    text-shadow: 0 0 5px var(--og);
}

.proContent>div a:hover svg {
    color: var(--col);
}

.proContent>div a>svg {
    font-size: 25px;

}

.proContent>div span {
    color: var(--col);
}

.proContent>div ul {
    width: 100%;
    display: flex;
    list-style: none;
    gap: 10px;
    color: var(--col);
    padding-top: 20px;
}

.proContent>div ul li {
    border: 1px solid gray;
    width: max-content;
    padding: 5px 10px;
    border-radius: 6px;
    color: var(--col);
    /* cursor: pointer; */
}

.proContent>div ul li:hover {
    border: 1px solid var(--og);
    /* background-color: var(--og); */
    color: var(--og);

}

@media screen and (max-width:786px) {
    .projectBox:nth-child(even) {
        display: flex;
        flex-direction: column;
    }

    .projectBox:nth-child(odd) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .proImg {
        width: 100%;
    }

    .proContent {
        width: 100%;
        padding:0;

    }
    .ProjectsDiv .info>h1 {
        font-size: 25px;
    }
    .ProjectsDiv .info h1 span {
        font-size: 22px;
    }

    .proContent>div p {
        font-size: 1rem;
    }

    .proContent>div a {
        font-size: 1.2rem;
    }
    .proContent>div span {
        font-size: 14px;
    }
    

    .proContent>div a>svg {
        font-size: 12px;
    }
    .proContent>div ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    
    .proContent>div ul li {
        border: 1px solid gray;
        width: max-content;
        padding: 5px 15px;
        border-radius: 6px;
        color: var(--col);
        font-size: 10px;
    }
    .ProjectsDiv {
        gap: 20px;
    }
    
}