.LayoutMain{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
}


.layoutPage{
    display: flex;
   flex: 1;
   position: relative;
}
.PageContainer{
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;
    background-color: var(--bl);

}
.LeftSide {
    position: fixed;
    left: 30px;
    top: 0;
    bottom: 0;
    width: 80px;
    z-index: 999;
}
.RightSide {
    position: fixed;
    right: 30px;
    top: 0;
    bottom: 0;
    width: 80px;
    z-index: 999;
}

@media screen and (max-width:786px) {
    .LeftSide {
       display: none;
    }
    .RightSide {
        display: none;

    }
}