.aboutContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 50px;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

.information {
    width: 100%;
    height: 100%;
    display: flex;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
}

.information .picture {
    position: relative;
    width: 80%;
    display: flex;
    align-items: center;
    object-fit: fill;
}

.information .picture img {
    width: 100%;
    height: auto;
    max-height: 450px;
    border-radius: 8px;
}

.information .picture .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 60%;
    background: linear-gradient(to left, rgba(29, 29, 29, 1), rgba(0, 0, 0, 0));
    border-radius: 8px;
    pointer-events: none;
}

.information .info {
    position: absolute;
    right: -20%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 40%;
    color: var(--col);
    font-family: "Source Sans 3", sans-serif;
}

.information .info>h1 {
    color: var(--col);
}

.information .info h1 span {
    color: var(--og);
    font-size: 30px;
    font-weight: 400;
}

.information .info p span {
    color: var(--og);
    font-weight: 600;
}

/* ------------------------- */

.scrollDiv {
    width: 100%;
    display: flex;
    height: 300px;
    align-items: center;
    overflow: hidden;
}

.scroll {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    gap: 70px;
}

.scroll div {
    display: flex;
    /* gap: 2em; */
}

.scroll p {
    font-size: 5em;
    color: var(--col);
    font-weight: 600;
    margin-bottom: 0;
    line-height: 10px;
    text-transform: uppercase;

}

.RightToLeft {
    animation: RightToLeft 10s linear infinite;
}

.RightToLeft p {
    -webkit-text-stroke: 1px var(--col);
    color: transparent;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 7px;

}

@keyframes RightToLeft {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-50%);
    }
}

.LeftToRight {
    animation: LeftToRight 10s linear infinite;
    color: var(--og);
}

.LeftToRight p {
    -webkit-text-stroke: 1px var(--og);
    color: transparent;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 7px;



}

@keyframes LeftToRight {
    from {
        transform: translateX(-50%);
    }
    
    to {
        transform: translateX(0%);
    }
}


@media screen and (max-width:786px) {
    .aboutContainer {
       width: 100%;
       height:60vh;
       padding-top: 0;
       gap: 10px;
    }
    .information .picture {
        width: 100%;
    }
    .information .picture img {
        display: none;
    }
    .information .info {
        position: absolute;
        right: 0;
        width: 100%;
    }
    .information .info>h1 {
        font-size: 25px;
    }

    .information .info h1 span {
        font-size: 22px;
    }

    .information .info p {
        font-size: 14px;
    }
    .scrollDiv {
       height: 200px;
    }
    
    .scroll {
        gap: 30px;
    }

    .scroll p {
        font-size: 1.8em;
    }

}