.Container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 15px;
    min-height: 400px;
    gap: 40px;
    background-color: var(--bl);
}

.MainDiv {
    width: 90%;
    /* max-width: 1400px; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

.MainDiv .boxes:nth-child(1) {
    width: 20%;
    height: 100%;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 10px;
    color: var(--col);
}

.MainDiv .boxes:nth-child(2) {
    width: 20%;

}

.MainDiv .boxes:nth-child(3) {
    width: 20%;
}

.MainDiv .boxes:nth-child(4) {
    width: 30%;
}

.boxes {
    height: 100%;
    gap: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 10px;
    color: var(--col);

}

.boxes img {
    width: 70%;
    height: 100px;
    object-fit: contain;
}

.boxes img:hover {
    filter: drop-shadow(0 0 5px var(--og));
}

.boxes p {
    width: 100%;
    font-weight: 600;
    margin-bottom: 10px;
}

.boxes ul {
    width: 100%;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.boxes ul li {
    width: fit-content;
    list-style: none;
    font-weight: 300;
    cursor: pointer;
    color: var(--col);
}

.boxes ul li:hover {
    color: var(--hov);
}

.SocialMedia {
    width: 100%;
    display: flex;
    gap: 20px;
}

.subscribe {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 15px;
}

.subscribe input {
    width: 70%;
    display: flex;
    height: 35px;
    border-radius: 45px;
    border: none;
    outline: none;
    text-indent: 15px;
}

.subscribe button {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-radius: 45px;
    border: none;
    outline: none;
    font-weight: 600;
    color: var(--bl);
    border: 1px solid var(--og);
}

.subscribe button:hover {
    background-color: var(--hov);
}

.RedSub {
    background-color: var(--og);
    color: var(--col);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.BlueSub {
    background-color: var(--og);
    color: var(--col);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


footer {
    /* width: 100%; */
    /* display: flex;
    align-items: center;
    justify-items: center;
    height: 100%; */
    position: relative;

}

footer p {
    color: var(--col);
    width: 100%;
    display: flex;
    align-items: center;
}

footer::before {
    content: '';
    position: absolute;
    top: 12px;
    background-color:var(--og);
    width: 100%;
    border-radius: 30px;
    height: 2px;
    left: 50%;
    transform: translate(60%, 0);
}
footer::after {
    content: '';
    position: absolute;

    top: 12px;
    background-color:var(--og);
    width: 100%;
    border-radius: 30px;
    height: 2px;
    right: 110%;
}


@media screen and (max-width:786px) {
    .Container {
        width: 100%;
        height: 100%;
    }

    .MainDiv {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .boxes {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
    }

    .boxes ul {
        width: 50%;
    }

    .additionalClass {
        display: flex;
        flex-direction: column;
    }
}