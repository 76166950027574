.Container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 10px;
}

.innerConainers {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 1400px;


}


/* ------------------------------------------------------------------------------------------------- */

.MainPageDiv {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin-top: 50px;
}

.Heading {
    display: flex;
    width: 60%;
    min-width: 300px;
    gap: 10px;
    flex-direction: column;
    font-family: "Source Sans 3", sans-serif;
}

.Heading>p {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 7px;
    font-weight: 400;
    color: var(--og);
    font-family: "Source Sans 3", sans-serif;

}

.Heading>h1 {
    font-size: 6rem;
    color: var(--nameCol);
    line-height: 100px;
    font-family: "Source Sans 3", sans-serif;

}

.Heading>h3 {
    font-size: 24px;
    color: var(--col);
    font-family: "Source Sans 3", sans-serif;

}

.Heading>h3 span {
    font-size: 35px;
    color: var(--og);
    font-family: "Source Sans 3", sans-serif;

}

.Heading>button {
    width: max-content;
    margin: 20px 0;
    height: 50px;
    font-size: 18px;
    padding: 0 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: none;
    background-color: var(--bl);
    border: 1px solid var(--og);
    color: var(--col);
    font-family: "Source Sans 3", sans-serif;
    cursor: pointer;
    transition: 0.4s ease-in-out;
}

.Heading>button:hover {
    box-shadow: 0 0 10px var(--og);
    text-shadow: 0 0 5px var(--og);
    background-color: var(--og);
}

.Profile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    min-width: 300px;
    position: relative;
    height: 500px;
}

.Profile img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: opacity 0.6s ease-in-out;
    position: absolute;
    cursor: pointer;
    border-radius: 10px;

    /* border-radius: 8px;
    filter: grayscale(1);
    transition: 0.3s ease-in-out; */
}

/* .Profile img:hover{
    filter: none;
} */

.Profile .FirstImg {
    opacity: 1;
    filter: drop-shadow(0 0 5px var(--col));
}

.Profile .SecondImg {
    opacity: 0;
}

.Profile:hover .FirstImg {
    opacity: 0;
    border-radius: 10px;

}

.Profile:hover .SecondImg {
    opacity: 1;
    border-radius: 10px;
}


@media screen and (max-width:786px) {
    .MainPageDiv {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-top: 180px;
    }

    .Heading {
        width: 100%;
    }

    .Profile {
        width: 100%;
        height: 350px;
        min-height: 350px;
        min-width: 100%;
        object-fit: contain;
    }

    .Profile img {
        object-fit: contain;
        width: 100%;
    }

    .Heading>p {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 4px;
    }

    .Heading>h1 {
        font-size: 2.6rem;
        line-height: 50px;
    }

    .Heading>h3 {
        font-size: 17px;
    }

    .Heading>h3 span {
        font-size: 18px;

    }

    .Heading>button {
        width: max-content;
        margin: 10px 0;
        height: 30px;
        font-size: 14px;
        padding: 0 10px;
    }

    .Profile .SecondImg {
        opacity: 1;
        border-radius: 10px;
    }

    .contain2 {
        padding-top: 50px;
    }

}

/* ------------------------------------------------------------------------------------------------- */


.contain1 {
    width: 100%;
    padding-top: 50px;
}

.contain2 {
    width: 100%;
    padding-top: 100px;
    height: 100%;
}

.contain3 {
    display: flex;
    width: 100%;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    margin: 100px 0;
}



@media screen and (max-width:768px) {
    .innerConainers {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 15px;

    }
}