.container {
    display: flex;
    max-width: 100%;
    height: 100px;
    background-color: var(--bl);
    align-items: center;
    justify-content: center;
    height: 100px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;

}
.scrolled {
    background-color: rgba(23, 23, 23, 0.7);
    backdrop-filter: blur(10px);
}
.nav {
    display: flex;
    width: 100%;
    height: 100%;
    padding:10px 35px;
    justify-content: space-between;
    align-items: center;
}

.links {
    display: flex;
    align-items: center;
    width: 500px;
    max-width: 500px;
    justify-content: space-between;

}

.links li {
    list-style: none;
    color: var(--col);
    font-size: 15px;
    font-weight: 600;
    padding: 5px;
    min-width:80px;
    text-align: center;
    font-family: "Poetsen One", sans-serif;
    cursor: pointer;
    display: flex;
    gap: 5px;
}
.links li span{
   color: var(--og);
}
.links li span:hover{
   color: var(--col);
}
.links li:hover{
   color: var(--og);
}

.logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.logo img {
    display: flex;
    width: 100%;
    height: 100%;
}
.logo img:hover{
    filter: drop-shadow(0 0 5px var(--og));
}
.links>button{
   width: 120px;
   height: 35px;
   font-size: 16px;
   border-radius: 8px;
   transition: 0.4s ease-in-out;
   background-color: var(--bl);
   color: var(--col);
   border:1px solid #80db66;
   cursor: pointer;

}
.links>button:hover{
   background-color: var(--og);
    box-shadow: 0 0 10px var(--og);
    text-shadow: 0 0 5px var(--og);

}

@media screen and (max-width:786px) {
    .container {
        height:150px;
    }
    .nav{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    .links button{
       display: none;
     }
     .links{
        width: 100%;
       display: flex;
       justify-content: space-around;
       align-items: center;
    }
}