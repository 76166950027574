@import url(https://fonts.googleapis.com/css2?family=Source+Sans+3:ital,wght@0,200..900;1,200..900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poetsen+One&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap);

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Source Sans 3", sans-serif;
  /* font-family: "Poetsen One", sans-serif; */
  /* font-family: "Bebas Neue", sans-serif; */
  font-style: normal;
}

:root{
  --nameCol:#fff;
  --col:#cacecc;
  /* --bl:#000; */
  /* --bl:#25262f; */
  --bl:#1d1d1d;
  --og:#80db66;
  --hov:#61a74d;
}


.Navbar_container__1ESpZ {
    display: flex;
    max-width: 100%;
    height: 100px;
    background-color: var(--bl);
    align-items: center;
    justify-content: center;
    height: 100px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 100000;
    transition: background-color 0.3s ease, -webkit-backdrop-filter 0.3s ease;
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease;
    transition: background-color 0.3s ease, backdrop-filter 0.3s ease, -webkit-backdrop-filter 0.3s ease;

}
.Navbar_scrolled__3NnSW {
    background-color: rgba(23, 23, 23, 0.7);
    -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
}
.Navbar_nav__2imOE {
    display: flex;
    width: 100%;
    height: 100%;
    padding:10px 35px;
    justify-content: space-between;
    align-items: center;
}

.Navbar_links__2x1pm {
    display: flex;
    align-items: center;
    width: 500px;
    max-width: 500px;
    justify-content: space-between;

}

.Navbar_links__2x1pm li {
    list-style: none;
    color: var(--col);
    font-size: 15px;
    font-weight: 600;
    padding: 5px;
    min-width:80px;
    text-align: center;
    font-family: "Poetsen One", sans-serif;
    cursor: pointer;
    display: flex;
    grid-gap: 5px;
    gap: 5px;
}
.Navbar_links__2x1pm li span{
   color: var(--og);
}
.Navbar_links__2x1pm li span:hover{
   color: var(--col);
}
.Navbar_links__2x1pm li:hover{
   color: var(--og);
}

.Navbar_logo__1H3m4 {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 70px;
    height: 70px;
    object-fit: cover;
}

.Navbar_logo__1H3m4 img {
    display: flex;
    width: 100%;
    height: 100%;
}
.Navbar_logo__1H3m4 img:hover{
    filter: drop-shadow(0 0 5px var(--og));
}
.Navbar_links__2x1pm>button{
   width: 120px;
   height: 35px;
   font-size: 16px;
   border-radius: 8px;
   transition: 0.4s ease-in-out;
   background-color: var(--bl);
   color: var(--col);
   border:1px solid #80db66;
   cursor: pointer;

}
.Navbar_links__2x1pm>button:hover{
   background-color: var(--og);
    box-shadow: 0 0 10px var(--og);
    text-shadow: 0 0 5px var(--og);

}

@media screen and (max-width:786px) {
    .Navbar_container__1ESpZ {
        height:150px;
    }
    .Navbar_nav__2imOE{
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
    }
    .Navbar_links__2x1pm button{
       display: none;
     }
     .Navbar_links__2x1pm{
        width: 100%;
       display: flex;
       justify-content: space-around;
       align-items: center;
    }
}
.Footer_Container__17hvU {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 40px 15px;
    min-height: 400px;
    grid-gap: 40px;
    gap: 40px;
    background-color: var(--bl);
}

.Footer_MainDiv__1Vj_Y {
    width: 90%;
    /* max-width: 1400px; */
    display: flex;
    align-items: flex-start;
    justify-content: center;
    height: 100%;
}

.Footer_MainDiv__1Vj_Y .Footer_boxes__7n_fx:nth-child(1) {
    width: 20%;
    height: 100%;
    grid-gap: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px 10px;
    color: var(--col);
}

.Footer_MainDiv__1Vj_Y .Footer_boxes__7n_fx:nth-child(2) {
    width: 20%;

}

.Footer_MainDiv__1Vj_Y .Footer_boxes__7n_fx:nth-child(3) {
    width: 20%;
}

.Footer_MainDiv__1Vj_Y .Footer_boxes__7n_fx:nth-child(4) {
    width: 30%;
}

.Footer_boxes__7n_fx {
    height: 100%;
    grid-gap: 20px;
    gap: 20px;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 20px 10px;
    color: var(--col);

}

.Footer_boxes__7n_fx img {
    width: 70%;
    height: 100px;
    object-fit: contain;
}

.Footer_boxes__7n_fx img:hover {
    filter: drop-shadow(0 0 5px var(--og));
}

.Footer_boxes__7n_fx p {
    width: 100%;
    font-weight: 600;
    margin-bottom: 10px;
}

.Footer_boxes__7n_fx ul {
    width: 100%;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    grid-gap: 5px;
    gap: 5px;
}

.Footer_boxes__7n_fx ul li {
    width: -moz-fit-content;
    width: fit-content;
    list-style: none;
    font-weight: 300;
    cursor: pointer;
    color: var(--col);
}

.Footer_boxes__7n_fx ul li:hover {
    color: var(--hov);
}

.Footer_SocialMedia__1zoDe {
    width: 100%;
    display: flex;
    grid-gap: 20px;
    gap: 20px;
}

.Footer_subscribe__3S30K {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    grid-gap: 15px;
    gap: 15px;
}

.Footer_subscribe__3S30K input {
    width: 70%;
    display: flex;
    height: 35px;
    border-radius: 45px;
    border: none;
    outline: none;
    text-indent: 15px;
}

.Footer_subscribe__3S30K button {
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 35px;
    border-radius: 45px;
    border: none;
    outline: none;
    font-weight: 600;
    color: var(--bl);
    border: 1px solid var(--og);
}

.Footer_subscribe__3S30K button:hover {
    background-color: var(--hov);
}

.Footer_RedSub___o9KW {
    background-color: var(--og);
    color: var(--col);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.Footer_BlueSub__3xPpK {
    background-color: var(--og);
    color: var(--col);
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}


footer {
    /* width: 100%; */
    /* display: flex;
    align-items: center;
    justify-items: center;
    height: 100%; */
    position: relative;

}

footer p {
    color: var(--col);
    width: 100%;
    display: flex;
    align-items: center;
}

footer::before {
    content: '';
    position: absolute;
    top: 12px;
    background-color:var(--og);
    width: 100%;
    border-radius: 30px;
    height: 2px;
    left: 50%;
    transform: translate(60%, 0);
}
footer::after {
    content: '';
    position: absolute;

    top: 12px;
    background-color:var(--og);
    width: 100%;
    border-radius: 30px;
    height: 2px;
    right: 110%;
}


@media screen and (max-width:786px) {
    .Footer_Container__17hvU {
        width: 100%;
        height: 100%;
    }

    .Footer_MainDiv__1Vj_Y {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .Footer_boxes__7n_fx {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: row;
    }

    .Footer_boxes__7n_fx ul {
        width: 50%;
    }

    .Footer_additionalClass__3VySz {
        display: flex;
        flex-direction: column;
    }
}
.Layout_LayoutMain__3KU2a{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
    height: 100vh;
}


.Layout_layoutPage__3BhwE{
    display: flex;
   flex: 1 1;
   position: relative;
}
.Layout_PageContainer__2oALr{
    width: 100%;
    height: 100%;
    display: flex;
    overflow: auto;
    background-color: var(--bl);

}
.Layout_LeftSide__24g63 {
    position: fixed;
    left: 30px;
    top: 0;
    bottom: 0;
    width: 80px;
    z-index: 999;
}
.Layout_RightSide__1-Ztp {
    position: fixed;
    right: 30px;
    top: 0;
    bottom: 0;
    width: 80px;
    z-index: 999;
}

@media screen and (max-width:786px) {
    .Layout_LeftSide__24g63 {
       display: none;
    }
    .Layout_RightSide__1-Ztp {
        display: none;

    }
}
.SideBar_MainDiv__3FWqR {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    writing-mode: vertical-rl;
}

.SideBar_MainDiv__3FWqR .SideBar_Content__2KHpB {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.SideBar_mail__3y8zV {
    text-decoration: none;
    transform: rotate(360deg);
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--col);
    font-size: 13px;
    cursor: pointer;
}

.SideBar_mail__3y8zV:hover {
    color: var(--hov);
}

.SideBar_MainDiv__3FWqR>.SideBar_Content__2KHpB::after {
    position: absolute;
    content: '';
    width: 1.6px;
    height: 120px;
    border-radius: 30px 30px 0 0;
    background-color: var(--col);
    bottom: 0;
}

.SideBar_Links__26wEW {
    position: absolute;
    bottom: 20%;
    display: flex;
    flex-direction: row;
    grid-gap: 15px;
    gap: 15px;
}


.SideBar_icons__29wqP {
    width: 30px;
    height: 30px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

}

.SideBar_icons__29wqP>svg {
    width: 100%;
    height: 100%;
    color: var(--col);
}

.SideBar_icons__29wqP>svg:hover {
    color: var(--og);
}


.MainPage_Container__3sVPO {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: 0 10px;
}

.MainPage_innerConainers__26Pbu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    max-width: 1400px;


}


/* ------------------------------------------------------------------------------------------------- */

.MainPage_MainPageDiv__2CaLe {
    display: flex;
    width: 100%;
    height: 100vh;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin-top: 50px;
}

.MainPage_Heading__28Qk1 {
    display: flex;
    width: 60%;
    min-width: 300px;
    grid-gap: 10px;
    gap: 10px;
    flex-direction: column;
    font-family: "Source Sans 3", sans-serif;
}

.MainPage_Heading__28Qk1>p {
    font-size: 24px;
    line-height: 1.3;
    letter-spacing: 7px;
    font-weight: 400;
    color: var(--og);
    font-family: "Source Sans 3", sans-serif;

}

.MainPage_Heading__28Qk1>h1 {
    font-size: 6rem;
    color: var(--nameCol);
    line-height: 100px;
    font-family: "Source Sans 3", sans-serif;

}

.MainPage_Heading__28Qk1>h3 {
    font-size: 24px;
    color: var(--col);
    font-family: "Source Sans 3", sans-serif;

}

.MainPage_Heading__28Qk1>h3 span {
    font-size: 35px;
    color: var(--og);
    font-family: "Source Sans 3", sans-serif;

}

.MainPage_Heading__28Qk1>button {
    width: max-content;
    margin: 20px 0;
    height: 50px;
    font-size: 18px;
    padding: 0 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: none;
    background-color: var(--bl);
    border: 1px solid var(--og);
    color: var(--col);
    font-family: "Source Sans 3", sans-serif;
    cursor: pointer;
    transition: 0.4s ease-in-out;
}

.MainPage_Heading__28Qk1>button:hover {
    box-shadow: 0 0 10px var(--og);
    text-shadow: 0 0 5px var(--og);
    background-color: var(--og);
}

.MainPage_Profile__3LN76 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40%;
    min-width: 300px;
    position: relative;
    height: 500px;
}

.MainPage_Profile__3LN76 img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    transition: opacity 0.6s ease-in-out;
    position: absolute;
    cursor: pointer;
    border-radius: 10px;

    /* border-radius: 8px;
    filter: grayscale(1);
    transition: 0.3s ease-in-out; */
}

/* .Profile img:hover{
    filter: none;
} */

.MainPage_Profile__3LN76 .MainPage_FirstImg__3ZHFi {
    opacity: 1;
    filter: drop-shadow(0 0 5px var(--col));
}

.MainPage_Profile__3LN76 .MainPage_SecondImg__3bY1f {
    opacity: 0;
}

.MainPage_Profile__3LN76:hover .MainPage_FirstImg__3ZHFi {
    opacity: 0;
    border-radius: 10px;

}

.MainPage_Profile__3LN76:hover .MainPage_SecondImg__3bY1f {
    opacity: 1;
    border-radius: 10px;
}


@media screen and (max-width:786px) {
    .MainPage_MainPageDiv__2CaLe {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        height: 100%;
        margin-top: 180px;
    }

    .MainPage_Heading__28Qk1 {
        width: 100%;
    }

    .MainPage_Profile__3LN76 {
        width: 100%;
        height: 350px;
        min-height: 350px;
        min-width: 100%;
        object-fit: contain;
    }

    .MainPage_Profile__3LN76 img {
        object-fit: contain;
        width: 100%;
    }

    .MainPage_Heading__28Qk1>p {
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 4px;
    }

    .MainPage_Heading__28Qk1>h1 {
        font-size: 2.6rem;
        line-height: 50px;
    }

    .MainPage_Heading__28Qk1>h3 {
        font-size: 17px;
    }

    .MainPage_Heading__28Qk1>h3 span {
        font-size: 18px;

    }

    .MainPage_Heading__28Qk1>button {
        width: max-content;
        margin: 10px 0;
        height: 30px;
        font-size: 14px;
        padding: 0 10px;
    }

    .MainPage_Profile__3LN76 .MainPage_SecondImg__3bY1f {
        opacity: 1;
        border-radius: 10px;
    }

    .MainPage_contain2__3mBgv {
        padding-top: 50px;
    }

}

/* ------------------------------------------------------------------------------------------------- */


.MainPage_contain1__3041r {
    width: 100%;
    padding-top: 50px;
}

.MainPage_contain2__3mBgv {
    width: 100%;
    padding-top: 100px;
    height: 100%;
}

.MainPage_contain3__ilFn0 {
    display: flex;
    width: 100%;
    padding: 15px 20px;
    justify-content: space-between;
    align-items: center;
    grid-gap: 10px;
    gap: 10px;
    margin: 100px 0;
}



@media screen and (max-width:768px) {
    .MainPage_innerConainers__26Pbu {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 0 15px;

    }
}
.AboutMe_aboutContainer__4VXjm {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    gap: 50px;
    align-items: center;
    justify-content: center;
    padding-top: 50px;
}

.AboutMe_information__2VPrN {
    width: 100%;
    height: 100%;
    display: flex;
    grid-gap: 50px;
    gap: 50px;
    align-items: center;
    justify-content: space-between;
}

.AboutMe_information__2VPrN .AboutMe_picture__I3VJG {
    position: relative;
    width: 80%;
    display: flex;
    align-items: center;
    object-fit: fill;
}

.AboutMe_information__2VPrN .AboutMe_picture__I3VJG img {
    width: 100%;
    height: auto;
    max-height: 450px;
    border-radius: 8px;
}

.AboutMe_information__2VPrN .AboutMe_picture__I3VJG .AboutMe_overlay__Tjv3y {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 60%;
    background: linear-gradient(to left, rgba(29, 29, 29, 1), rgba(0, 0, 0, 0));
    border-radius: 8px;
    pointer-events: none;
}

.AboutMe_information__2VPrN .AboutMe_info__1zra3 {
    position: absolute;
    right: -20%;
    display: flex;
    flex-direction: column;
    grid-gap: 10px;
    gap: 10px;
    width: 40%;
    color: var(--col);
    font-family: "Source Sans 3", sans-serif;
}

.AboutMe_information__2VPrN .AboutMe_info__1zra3>h1 {
    color: var(--col);
}

.AboutMe_information__2VPrN .AboutMe_info__1zra3 h1 span {
    color: var(--og);
    font-size: 30px;
    font-weight: 400;
}

.AboutMe_information__2VPrN .AboutMe_info__1zra3 p span {
    color: var(--og);
    font-weight: 600;
}

/* ------------------------- */

.AboutMe_scrollDiv__1n7Rp {
    width: 100%;
    display: flex;
    height: 300px;
    align-items: center;
    overflow: hidden;
}

.AboutMe_scroll__2R6cJ {
    display: flex;
    flex-direction: column;
    white-space: nowrap;
    grid-gap: 70px;
    gap: 70px;
}

.AboutMe_scroll__2R6cJ div {
    display: flex;
    /* gap: 2em; */
}

.AboutMe_scroll__2R6cJ p {
    font-size: 5em;
    color: var(--col);
    font-weight: 600;
    margin-bottom: 0;
    line-height: 10px;
    text-transform: uppercase;

}

.AboutMe_RightToLeft__3i9x2 {
    animation: AboutMe_RightToLeft__3i9x2 10s linear infinite;
}

.AboutMe_RightToLeft__3i9x2 p {
    -webkit-text-stroke: 1px var(--col);
    color: transparent;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 7px;

}

@keyframes AboutMe_RightToLeft__3i9x2 {
    from {
        transform: translateX(0%);
    }

    to {
        transform: translateX(-50%);
    }
}

.AboutMe_LeftToRight__3biR- {
    animation: AboutMe_LeftToRight__3biR- 10s linear infinite;
    color: var(--og);
}

.AboutMe_LeftToRight__3biR- p {
    -webkit-text-stroke: 1px var(--og);
    color: transparent;
    font-family: "Bebas Neue", sans-serif;
    letter-spacing: 7px;



}

@keyframes AboutMe_LeftToRight__3biR- {
    from {
        transform: translateX(-50%);
    }
    
    to {
        transform: translateX(0%);
    }
}


@media screen and (max-width:786px) {
    .AboutMe_aboutContainer__4VXjm {
       width: 100%;
       height:60vh;
       padding-top: 0;
       grid-gap: 10px;
       gap: 10px;
    }
    .AboutMe_information__2VPrN .AboutMe_picture__I3VJG {
        width: 100%;
    }
    .AboutMe_information__2VPrN .AboutMe_picture__I3VJG img {
        display: none;
    }
    .AboutMe_information__2VPrN .AboutMe_info__1zra3 {
        position: absolute;
        right: 0;
        width: 100%;
    }
    .AboutMe_information__2VPrN .AboutMe_info__1zra3>h1 {
        font-size: 25px;
    }

    .AboutMe_information__2VPrN .AboutMe_info__1zra3 h1 span {
        font-size: 22px;
    }

    .AboutMe_information__2VPrN .AboutMe_info__1zra3 p {
        font-size: 14px;
    }
    .AboutMe_scrollDiv__1n7Rp {
       height: 200px;
    }
    
    .AboutMe_scroll__2R6cJ {
        grid-gap: 30px;
        gap: 30px;
    }

    .AboutMe_scroll__2R6cJ p {
        font-size: 1.8em;
    }

}
.Projects_ProjectsDiv__13vYc {
    width: 100%;
    flex-wrap: wrap;
    display: flex;
    justify-content: center;
    align-items: center;
    grid-gap: 40px;
    gap: 40px;
}

.Projects_projectDivs__2_cNy {
    width: 100%;
    flex-wrap: wrap;
    grid-gap: 90px;
    gap: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.6s ease-in-out;

}

.Projects_ProjectsDiv__13vYc>.Projects_info__3ptPd {
    display: flex;
    width: 100%;
    align-items: flex-end;
    justify-content: flex-start;
}

.Projects_ProjectsDiv__13vYc .Projects_info__3ptPd>h1 {
    color: var(--col);
}

.Projects_ProjectsDiv__13vYc .Projects_info__3ptPd h1 span {
    color: var(--og);
    font-size: 30px;
    font-weight: 400;
}


.Projects_projectBox__fraOv:nth-child(even) {
    width: 100%;
    display: flex;
    flex-direction: row-reverse;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
    transition: all 0.6s ease-in-out;
}

.Projects_projectBox__fraOv:nth-child(odd) {
    width: 100%;
    display: flex;
    flex-direction: row;
    grid-gap: 20px;
    gap: 20px;
    justify-content: space-between;
    transition: all 0.6s ease-in-out;
}

.Projects_proImg__2zR8c {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
}

.Projects_proImg__2zR8c img {
    width: 100%;
    height: 100%;
    object-fit: fill;
    border-radius: 10px;
    cursor: pointer;

}

.Projects_proImg__2zR8c .Projects_overlay__24Q6b {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    background: #65998b6e;
    border-radius: 8px;
    pointer-events: none;
}

.Projects_proImg__2zR8c:hover .Projects_overlay__24Q6b {
    display: none;

}

.Projects_proImg__2zR8c:hover {
    box-shadow: 0 0 10px 1px var(--og);
    border-radius: 8px;
}

.Projects_proContent__1EnOi {
    width: 50%;
    display: flex;
    align-items: center;
    padding: 20px 30px;
}

.Projects_proContent__1EnOi>div {
    width: 100%;
    display: flex;
    flex-direction: column;

}

.Projects_proContent__1EnOi>div p {
    font-size: 1.2rem;
    font-weight: 400;
    line-height: 1.2;
    color: var(--og);
}

.Projects_proContent__1EnOi>div a {
    font-size: 2rem;
    font-weight: 600;
    line-height: 1.5;
    color: var(--col);
    text-decoration: none;
}

.Projects_proContent__1EnOi>div a:hover {
    -webkit-text-stroke: 1px var(--og);
    color: var(--col);
    text-shadow: 0 0 5px var(--og);
}

.Projects_proContent__1EnOi>div a:hover svg {
    color: var(--col);
}

.Projects_proContent__1EnOi>div a>svg {
    font-size: 25px;

}

.Projects_proContent__1EnOi>div span {
    color: var(--col);
}

.Projects_proContent__1EnOi>div ul {
    width: 100%;
    display: flex;
    list-style: none;
    grid-gap: 10px;
    gap: 10px;
    color: var(--col);
    padding-top: 20px;
}

.Projects_proContent__1EnOi>div ul li {
    border: 1px solid gray;
    width: max-content;
    padding: 5px 10px;
    border-radius: 6px;
    color: var(--col);
    /* cursor: pointer; */
}

.Projects_proContent__1EnOi>div ul li:hover {
    border: 1px solid var(--og);
    /* background-color: var(--og); */
    color: var(--og);

}

@media screen and (max-width:786px) {
    .Projects_projectBox__fraOv:nth-child(even) {
        display: flex;
        flex-direction: column;
    }

    .Projects_projectBox__fraOv:nth-child(odd) {
        width: 100%;
        display: flex;
        flex-direction: column;
    }

    .Projects_proImg__2zR8c {
        width: 100%;
    }

    .Projects_proContent__1EnOi {
        width: 100%;
        padding:0;

    }
    .Projects_ProjectsDiv__13vYc .Projects_info__3ptPd>h1 {
        font-size: 25px;
    }
    .Projects_ProjectsDiv__13vYc .Projects_info__3ptPd h1 span {
        font-size: 22px;
    }

    .Projects_proContent__1EnOi>div p {
        font-size: 1rem;
    }

    .Projects_proContent__1EnOi>div a {
        font-size: 1.2rem;
    }
    .Projects_proContent__1EnOi>div span {
        font-size: 14px;
    }
    

    .Projects_proContent__1EnOi>div a>svg {
        font-size: 12px;
    }
    .Projects_proContent__1EnOi>div ul {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
    }
    
    .Projects_proContent__1EnOi>div ul li {
        border: 1px solid gray;
        width: max-content;
        padding: 5px 15px;
        border-radius: 6px;
        color: var(--col);
        font-size: 10px;
    }
    .Projects_ProjectsDiv__13vYc {
        grid-gap: 20px;
        gap: 20px;
    }
    
}
.ContactMe_footerContainer__1q51N {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    /* height: 100vh; */
    margin-top: 50px;
    margin-bottom: 80px;
    /* background: rgb(221, 161, 161); */
    padding: 0;
}

.ContactMe_getInTouch__3fDgM {
    width: 100%;
    max-width: 1400px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.ContactMe_getInTouch__3fDgM>div {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    grid-gap: 12px;
    gap: 12px;
}

.ContactMe_getInTouch__3fDgM>div>h1 {
    color: var(--col);
}
.ContactMe_getInTouch__3fDgM>div>h1 span {
    color: var(--og);
    font-size: 30px;
    font-weight: 400;
}

.ContactMe_getInTouch__3fDgM>div>span {
    color: var(--col);
    font-size: 4rem;
    font-weight: 600;
}

.ContactMe_getInTouch__3fDgM>div>p {
    color: var(--col);
    font-size: 1.2rem;
    font-weight: 600;
}

.ContactMe_getInTouch__3fDgM>div>a {
    width: max-content;
    margin: 20px 0;
    height: 50px;
    font-size: 18px;
    padding: 0 20px;
    font-weight: 600;
    letter-spacing: 1.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    border: none;
    background-color: var(--bl);
    border:1px solid var(--og);
    /* background-color: var(--og); */
    color: var(--col);
    font-family: "Source Sans 3", sans-serif;
    cursor: pointer;
    transition: 0.4s ease-in-out;
    text-decoration: none;
}

.ContactMe_getInTouch__3fDgM>div>a:hover {
    box-shadow: 0 0 10px var(--og);
    text-shadow: 0 0 5px var(--og);
    background-color: var(--og);

}


@media screen and (max-width:786px) {
    
    .ContactMe_getInTouch__3fDgM>div>h1 {
        font-size: 25px;
    }
    .ContactMe_getInTouch__3fDgM>div>h1 span {
        font-size: 22px;
    }
    
    .ContactMe_getInTouch__3fDgM>div>span {
        font-size: 2.5rem;
    }
    
    .ContactMe_getInTouch__3fDgM>div>p {
        font-size: 14px;
        font-weight: 600;
        text-align: center;
    }
    
    .ContactMe_getInTouch__3fDgM>div>a {
        width: max-content;
        margin: 10px 0;
        height: 30px;
        font-size: 14px;
        padding: 0 15px;
    }
    .ContactMe_footerContainer__1q51N>.ContactMe_Links__24ooW{
     width: 100%;
    }
}
.IconsFooter_iconsfot__3NYbO {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bl);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
}

.IconsFooter_iconsfot__3NYbO .IconsFooter_Links__MVQRt {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
}

.IconsFooter_iconsfot__3NYbO .IconsFooter_Links__MVQRt .IconsFooter_icons__2O1am {
    width: 30px;
    height: 30px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

}

.IconsFooter_iconsfot__3NYbO>.IconsFooter_Links__MVQRt .IconsFooter_icons__2O1am svg {
    width: 100%;
    height: 100%;
    color: var(--col);
}

.IconsFooter_iconsfot__3NYbO>.IconsFooter_Links__MVQRt .IconsFooter_icons__2O1am svg:hover {
    color: var(--og);
}


@media screen and (max-width:786px) {
    .IconsFooter_iconsfot__3NYbO{
        display: flex;
        padding: 20px 0;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px;
    }
}
