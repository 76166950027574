.iconsfot {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--bl);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    display: none;
}

.iconsfot .Links {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
}

.iconsfot .Links .icons {
    width: 30px;
    height: 30px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

}

.iconsfot>.Links .icons svg {
    width: 100%;
    height: 100%;
    color: var(--col);
}

.iconsfot>.Links .icons svg:hover {
    color: var(--og);
}


@media screen and (max-width:786px) {
    .iconsfot{
        display: flex;
        padding: 20px 0;
        box-shadow: rgba(0, 0, 0, 0.45) 0px 5px 15px;
    }
}