.MainDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
    writing-mode: vertical-rl;
}

.MainDiv .Content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
}

.mail {
    text-decoration: none;
    transform: rotate(360deg);
    font-weight: 600;
    letter-spacing: 2px;
    color: var(--col);
    font-size: 13px;
    cursor: pointer;
}

.mail:hover {
    color: var(--hov);
}

.MainDiv>.Content::after {
    position: absolute;
    content: '';
    width: 1.6px;
    height: 120px;
    border-radius: 30px 30px 0 0;
    background-color: var(--col);
    bottom: 0;
}

.Links {
    position: absolute;
    bottom: 20%;
    display: flex;
    flex-direction: row;
    gap: 15px;
}


.icons {
    width: 30px;
    height: 30px;
    padding: 5px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;

}

.icons>svg {
    width: 100%;
    height: 100%;
    color: var(--col);
}

.icons>svg:hover {
    color: var(--og);
}

